import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


import Seo from "../components/seo"

import { FaArrowCircleRight } from "react-icons/fa"

const query = graphql`
{
    au: allFile(filter: {relativeDirectory: {eq: "partners/auxilliary"}}) {
        auxilliary: nodes {
        childImageSharp {
          gatsbyImageData(
            width: 156
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
  
            fixed(width: 130) {
                originalName
                }
        }
        id
        }
    }

    ca: allFile(filter: {relativeDirectory: {eq: "partners/cables"}}) {
        cables: nodes {
        childImageSharp {
          gatsbyImageData(
            width: 156
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
  
            fixed(width: 130) {
                originalName
                }
        }
        id
        }
    }

    ne: allFile(filter: {relativeDirectory: {eq: "partners/network"}}) {
        network: nodes {
        childImageSharp {
          gatsbyImageData(
            width: 156
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )

            fixed(width: 130) {
            originalName
            }
        }
        id
        }
    }
}

`



const PartnersPage = () => {

  const partners = useStaticQuery(query)
  console.log(partners)
  const { au: { auxilliary }, ca: { cables }, ne: { network } } = partners


  return <Layout>
    <Seo title="Partners" description="Martindale Technologies Inc. official partner list" />
    <section className="bg-grey services-section partners-section">
      <section className="section-center">


        <h2 className="section-title">Our Partners</h2>
        <p className="services-text" style={{ maxWidth: "800px" }}>Martindale Techonologies boasts a wide array of industry partners ranging
          from network equipment providers and cabling distributors.</p>


        <div className="partner-header">
          <h3>Networks</h3>
        </div>
        <div className="partner-wrapper">
          {network.map((partner) => {
            const image = getImage(partner)
            return <div> <GatsbyImage key={partner.id} image={image} className="partner-img" alt={partner.childImageSharp.orginalName}></GatsbyImage> </div>
          })
          }
        </div>

        <div className="partner-line">

        </div>
        <div className="partner-header">
          <h3>CCTV & Auxilliary</h3>
        </div>
        <div className="partner-wrapper">
          {auxilliary.map((partner) => {
            const image = getImage(partner)
            return <div> <GatsbyImage key={partner.id} image={image} className="partner-img" alt={partner.childImageSharp.orginalName}></GatsbyImage> </div>
          })
          }
        </div>
        <div className="partner-line">

        </div>
        <div className="partner-header">
          <h3>Wires and Cables</h3>
        </div>
        <div className="partner-wrapper">
          {cables.map((partner) => {
            const image = getImage(partner)
            return <div><GatsbyImage key={partner.id} image={image} className="partner-img" alt={partner.childImageSharp.orginalName}></GatsbyImage> </div>
          })
          }
        </div>

      </section>
    </section>

    <section>
      <div className="index-second contact-div">
        <h2>Want to become a partner?</h2>
        <Link to="/contact/" ><button className="btn">Shoot Us A Message
          <FaArrowCircleRight className="btn-icon" /></button></Link>
      </div>
    </section>




  </Layout>
}

export default PartnersPage